<template>
  <div>
    <el-table :data="list" style="width: 100%" v-loading="loading">
      <el-table-column prop="id" label="ID" width="80"> </el-table-column>
      <el-table-column prop="buyer_name" label="买家名称"> </el-table-column>
      <el-table-column prop="sales_id" label="报单ID"> </el-table-column>
      <el-table-column prop="amount" label="实付金额"> </el-table-column>
      <el-table-column prop="commission" label="分佣金额"> </el-table-column>
      <el-table-column prop="commission" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 1">入账中</span>
          <span v-if="scope.row.status == 2">已入账</span>
          <span v-if="scope.row.status == 3">无效</span>
        </template>
      </el-table-column>
      <el-table-column prop="course" label="课程名称">
      </el-table-column>
      <el-table-column prop="commission" label="商品图片">
        <template slot-scope="scope">
          <el-image :src="imageUrl + scope.row.order_pic"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="commission" label="操作" width="180">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 1">
            <el-link type="primary" @click="invalid(scope.row.id)">
              将记推广录置为无效
            </el-link>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-batch">
      <span class="fl-r">
        <el-pagination
          small
          background
          :current-page="page"
          :page-sizes="[10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="setPageSize"
          @current-change="setPage"
          :total="total"
        >
        </el-pagination>
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    id: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      list: [],
      page: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      imageUrl: config.imageUrl,
    };
  },
  watch: {
    id: {
      handler(val) {
        this.getList()
      },
    },
  },
  methods: {
    ...mapActions("promote", ["listPromoterRecord", "invalidRecord"]),
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    async invalid(id) {
      const { res_info } = await this.invalidRecord({ recordId: id });
      if(res_info != 'ok')return
      this.$message.success('成功')
      this.getList()
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let form = {
        promoterId: this.id,
        page: this.page,
        pageSize: this.pageSize,
      };
      const { data } = await this.listPromoterRecord(form);
      this.list = data.list;
      this.total = data.total;
      this.loading = false;
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style></style>

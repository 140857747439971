<template>
  <div>
    <div class="main-title">推广员列表</div>
    <div class="content-list-page page-content">
      <div class="list-head-box">
        <div data-v-69874ad0="" class="list-head">
          <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
          <ul data-v-69874ad0="">
            ********************
          </ul>
        </div>
      </div>

      <div class="padding-t-sm padding-b-sm">
        <span class="fl-l">
          <el-select
            filterable
            v-model="searchData.adviser"
            clearable
            size="mini"
            placeholder="咨询师"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in advisers"
              :key="item.id"
            ></el-option>
          </el-select>
        </span>
        <span class="fl-l margin-l-sm">
          <el-input
            v-model.trim="searchData.infoStatus"
            placeholder="手机号"
          ></el-input>
        </span>
        <span class="fl-l margin-l-sm">
          <el-input
            v-model.trim="searchData.mobile"
            placeholder="手机号"
          ></el-input>
        </span>
        <span class="fl-l margin-l-sm">
          <el-input
            v-model.trim="searchData.realName"
            placeholder="真实姓名"
          ></el-input>
        </span>
        <span class="margin-l-sm fl-l">
          <el-select
            v-model="searchData.status"
            clearable
            size="mini"
            placeholder="状态选择"
          >
            <el-option label="禁用" :value="0"></el-option>
            <el-option label="启用" :value="1"></el-option>
          </el-select>
        </span>

        <span class="margin-l-sm fl-l">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="search"
            >搜索</el-button
          >
        </span>

        <span class="fl-r">
          <router-link to="/promote/add" class="router-link">
            <el-button type="primary" size="small">添加人员</el-button>
          </router-link>
        </span>

        <div style="clear: both"></div>
      </div>

      <div class="table-content">
        <el-table
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
          :cell-class-name="rowItem"
          ref="singleTable"
          :data="tableData"
          highlight-current-row
          @current-change="handleCurrentChange"
          v-loading="loading"
          style="width: 100%; background: #fff"
        >
          <el-table-column label="ID" property="id" width="80">
          </el-table-column>
          <el-table-column label="推广人" property="user_name">
          </el-table-column>
          <el-table-column label="等级" property="level_name">
          </el-table-column>
          <el-table-column label="审核人" property="auditor_name">
          </el-table-column>
          <el-table-column
            label="用户信息状态"
            property="bank_name"
            width="160"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.info_status == 0">未完善</span>
              <span v-else-if="scope.row.info_status == 1">待审核</span>
              <span v-else-if="scope.row.info_status == 2">已审核</span>
              <span v-else-if="scope.row.info_status == 3">审核失败</span>
            </template>
          </el-table-column>
          <el-table-column label="用户金额信息">
            <template slot-scope="scope">
              <el-popover placement="right" width="400" trigger="click">
                <div>
                  <el-descriptions title="金额信息">
                    <el-descriptions-item label="积累金额">{{
                      scope.row.total_amount
                    }}</el-descriptions-item>
                    <el-descriptions-item label="冻结金额">{{
                      scope.row.frozen_amount
                    }}</el-descriptions-item>
                    <el-descriptions-item label="已提现金额">{{
                      scope.row.withdrawal_amount
                    }}</el-descriptions-item>
                  </el-descriptions>
                </div>
                <el-link slot="reference" type="primary">查看</el-link>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="用户信息">
            <template slot-scope="scope">
              <el-popover placement="bottom" width="900" trigger="click">
                <div>
                  <el-descriptions title="个人信息">
                    <el-descriptions-item label="真实姓名">{{
                      scope.row.real_name
                    }}</el-descriptions-item>
                    <el-descriptions-item label="身份证号码">{{
                      scope.row.id_card
                    }}</el-descriptions-item>
                    <el-descriptions-item label="手机号">{{
                      scope.row.mobile
                    }}</el-descriptions-item>
                    <el-descriptions-item label="银行卡号">{{
                      scope.row.bank_account
                    }}</el-descriptions-item>
                    <el-descriptions-item label="支行">{{
                      scope.row.bank_name
                    }}</el-descriptions-item>
                  </el-descriptions>
                </div>
                <el-link slot="reference" type="primary">查看</el-link>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column property="address" label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1" class="state-up"
                ><i></i>启用</span
              >
              <span v-if="scope.row.status == 0" class="state-down"
                ><i></i>禁用</span
              >
            </template>
          </el-table-column>
          <el-table-column label="审核时间" property="audited_at">
          </el-table-column>
          <el-table-column label="咨询师" property="adviser_name">
          </el-table-column>

          <el-table-column property="address" label="操作" width="180">
            <template slot-scope="scope">
              <span v-if="scope.row.info_status == 1">
                <el-link
                  slot="reference"
                  type="primary"
                  @click="
                    dialogVisible = true;
                    form.promoterId = scope.row.id;
                  "
                >
                  审核 </el-link
                >-
              </span>
              <span>
                <el-popconfirm
                  icon="el-icon-info"
                  icon-color="red"
                  title="确定改变状态吗？"
                  @confirm="changeStute(scope.row)"
                >
                  <el-link slot="reference" type="primary">
                    {{ scope.row.status == 0 ? "启用" : "禁用" }}
                  </el-link>
                </el-popconfirm>
              </span>
              <span
                @click="
                  productDialogVisible = true;
                  id = scope.row.id;
                "
                >-
                <el-link slot="reference" type="primary">
                  添加推广产品
                </el-link>
              </span>
              <span @click="record(scope.row.id)"
                >-
                <el-link slot="reference" type="primary"> 记录 </el-link>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r">
          <el-pagination
            small
            background
            :current-page="page"
            :page-sizes="[10, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="setPageSize"
            @current-change="setPage"
            :total="total"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>

      <el-dialog
        title="审核"
        :visible.sync="dialogVisible"
        append-to-body
        width="30%"
      >
        <el-form :model="form" ref="form" label-width="100px">
          <el-form-item label="审核结果：">
            <el-radio v-model="form.isPass" :label="true">通过</el-radio>
            <el-radio v-model="form.isPass" :label="false">失败</el-radio>
          </el-form-item>
          <el-form-item
            label="失败理由:"
            v-if="form.isPass == false"
            prop="reason"
            :rules="[
              { required: true, message: '请输入失败的理由', trigger: 'blur' },
              { max: 225, message: '长度在225个字符以内', trigger: 'blur' },
            ]"
          >
            <el-input
              type="textarea"
              style="width: calc(100% - 100px)"
              placeholder="请输入内容"
              v-model="form.reason"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">立即创建</el-button>
            <el-button
              @click="
                dialogVisible = false;
                form = { isPass: true };
              "
              >取消</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>

      <el-dialog
        title="添加推广产品"
        :visible.sync="productDialogVisible"
        append-to-body
        width="50%"
        ><popularize-Product @select="select" :id="id"></popularize-Product>
      </el-dialog>

      <el-dialog
        title="记录列表"
        :visible.sync="recordDialogVisible"
        append-to-body
        width="60%"
      >
        <record-List class="record" :id="promoterId"></record-List>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import recordList from "@/components/recordList.vue";
import popularizeProduct from "@/components/popularizeProduct";
export default {
  name: "template-list",
  components: {
    popularizeProduct,
    recordList,
  },
  data() {
    return {
      dialogVisible: false,
      recordDialogVisible: false,
      productDialogVisible: false,
      sharePic: "",
      shareUrl: "",
      page: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      imageUrl: config.imageUrl,
      searchData: {},
      tableData: [],
      advisers: [],
      form: {
        isPass: true,
      },
      productList: [],
      id: null,
      list: [],
      promoterId: null,
    };
  },
  methods: {
    ...mapActions("promote", [
      "listPromoter",
      "selectListAdviser",
      "setPromoterStatus",
      "infoCheck",
      "addProducts",
    ]),
    record(id) {
      this.promoterId = id;
      this.recordDialogVisible = true;
    },
    async select(e) {
      console.log(e);
      let arr = [];
      e.forEach((c) => {
        arr.push(c.id);
      });
      let form = {
        promoter: this.id,
        product_ids: arr,
      };
      const { res_info } = await this.addProducts(form);
      if (res_info != "ok") return;
      this.$message.success("设置成功");
      this.productDialogVisible = false;
    },
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    headeCellStyle({ row, rowIndex }) {
      return "{padding:0}";
    },
    rowItem() {
      return "row-item";
    },
    search() {
      this.tableData = [];
      this.page = 1;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let data = {
        name: this.name,
        status: this.status,
        page: this.page,
        pageSize: this.pageSize,
      };
      const res = await this.selectListAdviser();
      this.advisers = res.data;
      this.listPromoter(data).then((res) => {
        this.loading = false;
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    async onSubmit() {
      const { res_info } = await this.infoCheck(this.form);
      if (res_info != "ok") return;
      this.$message.success("审核成功！");
      this.dialogVisible = false;
      this.getList();
    },
    async changeStute(item) {
      let form = {
        id: item.id,
        status: item.status == 0 ? 1 : 0,
      };
      const { res_info } = await this.setPromoterStatus(form);
      if (res_info != "ok") return;
      this.$message.success("修改成功！");
      this.getList();
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    editDetail(row) {
      this.$router.push("/extension/edit/" + row.id);
    },
    detail(row) {
      this.$router.push("/promotion/detail/" + row.id + "/promotionUsers");
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style>
.bg-img {
}

.qrcode {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px;
  background: #fff;
  margin: 5px;
}
.record {
  padding-bottom: 20px;
}
</style>

<template>
  <div style="padding: 10px">
    <div>
      <span class="fl-l margin-l-sm">
        <el-input v-model="searchData.name" placeholder="商品名称"></el-input>
      </span>
      <span class="margin-l-sm fl-l">
        <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="search"
          >搜索</el-button
        >
      </span>
    </div>
    <div class="roll">
      <el-table
        ref="multipleTable"
        :data="tableData"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        tooltip-effect="dark"
        style="width: 100%"
        v-loading="loading"
      >
        <el-table-column type="selection"> </el-table-column>
        <el-table-column prop="id" label="ID"> </el-table-column>
        <el-table-column prop="name" label="组合购"> </el-table-column>
        <el-table-column label="图片">
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="imageUrl + scope.row.pic"
            ></el-image>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="table-batch">
      <span class="fl-r">
        <el-pagination
          small
          background
          :current-page="page"
          :page-sizes="[10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="setPageSize"
          @current-change="setPage"
          :total="total"
        >
        </el-pagination>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="select" size="mini">确 定</el-button>
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    id: {
      type: Number,
    },
  },
  watch: {
    id: {
      handler() {
        this.getList();
        this.searchData.name = "";
      },
    },
  },
  data() {
    return {
      searchData: {},
      tableData: [],
      loading: false,
      page: 1,
      imageUrl: config.imageUrl,
      pageSize: 10,
      total: 0,
      products: [],
      selectTable: [],
    };
  },
  methods: {
    ...mapActions("promote", ["selectListProduct", "getEditProducts"]),
    search() {
      this.getList();
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.selectTable[this.page] = this.$refs.multipleTable.selection;
      this.selectTable = this.unique(this.selectTable);
      this.page = page;
      this.getList();
    },

    select() {
      let arr = [];
      this.selectTable[this.page] = this.$refs.multipleTable.selection;
      this.selectTable.forEach((v) => {
        if (v != undefined) {
          v.forEach((c) => {
            if (c != undefined) {
              arr.push(c);
            }
          });
        }
      });
      this.$emit("select", arr);
    },
    unique(arr) {
      return [...new Set(arr)];
    },
    toggleSelection(rows) {
      this.$nextTick(() => {
        rows?.forEach((row) => {
          let index = this.tableData.findIndex((c) => c.id == row.id);
          this.$refs.multipleTable.toggleRowSelection(
            this.tableData[index],
            true
          );
        });
      });
    },
    async getList() {
      this.loading = true;
      let form = {
        ...this.searchData,
        page: this.page,
        pageSize: this.pageSize,
      };
      const { data } = await this.selectListProduct(form);
      const res = await this.getEditProducts(this.id);
      this.products = res.data.products;
      this.tableData = data.list;
      this.total = data.total;
      this.toggleSelection(this.products);
      this.loading = false;
    },
  },
  mounted() {
    this.selectTable = [];
    this.getList();
  },
  // updated() {
  // if(this.selectTable === null)

  // else
  // this.toggleSelection(this.selectTable);
  // },
};
</script>

<style scoped>
.roll {
  width: 100%;
  height: 400px;
  overflow: auto;
}
</style>
